<template>
    <div>
        <v-form
            v-if="submitted <= 1"
            id="sf_form"
            ref="sf-case-form"
            v-model="isValid"
            :disabled="submitted !== 0"
            :action="action"
            method="POST"
            target="sf_to_target"
            @submit="progressStage"
        >
            <div v-for="(value, item) in form" :key="item">
                <input :name="item" :value="value" type="hidden" />
            </div>
            <input name="retURL" :value="retURL" type="hidden" />

            <div label="User Edit Pane">
                <v-row v-if="debugBool" dense>
                    <v-col>
                        <v-checkbox
                            v-model="debugBool"
                            v-bind="$attrs"
                            label="Debug Flag"
                        />
                    </v-col>
                    <v-col>
                        <base-text-field
                            v-model="form.debugEmail"
                            v-bind="$attrs"
                            label="Debug Email"
                        />
                    </v-col>
                </v-row>

                <v-row label="Names">
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="00N3w000009CuPf"
                            v-model="form['00N3w000009CuPf']"
                            v-bind="$attrs"
                            label="First Name"
                            :rules="[required, maxLength(100)]"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="00N3w000009CuPk"
                            v-model="form['00N3w000009CuPk']"
                            v-bind="$attrs"
                            label="Last Name"
                            :rules="[required, maxLength(100)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>
                <v-row label="Email Phone">
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="email"
                            v-model="email"
                            v-bind="$attrs"
                            label="Email Address"
                            :rules="[required, maxLength(50)]"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="00N3w000009CuPz"
                            v-model="form[`00N3w000009CuPz`]"
                            v-mask="'(###)###-####'"
                            placeholder="(###)###-####"
                            v-bind="$attrs"
                            label="Phone"
                            :rules="[required, maxLength(25)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>

                <div label="User Entry">
                    <base-text-field
                        v-if="!hideSubject"
                        id="subject"
                        v-model="form.subject"
                        class="py-3"
                        v-bind="$attrs"
                        label="Subject"
                        :rules="[required, maxLength(80)]"
                        hide-details
                        dark
                    />
                    <base-textarea
                        v-model="description"
                        class="py-3"
                        v-bind="$attrs"
                        :rules="[required]"
                        label="Description"
                        hide-details
                        dark
                    />
                </div>

                <base-select
                    v-if="!hideCaseType"
                    v-model="form[`00Nd00000076HZs`]"
                    title="Case Type"
                    v-bind="$attrs"
                    label="Case Type"
                    :rules="[required]"
                    :items="caseTypeList"
                    class="py-3"
                    hide-details
                    dark
                />

                <div v-if="showFullAddress">
                    <base-text-field
                        id="00N3w000009CuPp"
                        v-model="form[`00N3w000009CuPp`]"
                        v-bind="$attrs"
                        label="Company Name"
                        :rules="[maxLength(255)]"
                        class="py-3"
                        hide-details
                        dark
                    />

                    <base-text-field
                        id="00N3w000009CuQ4"
                        v-model="form[`00N3w000009CuQ4`]"
                        v-bind="$attrs"
                        label="Street 1"
                        :rules="[maxLength(200)]"
                        class="py-3"
                        hide-details
                        dark
                    />

                    <base-text-field
                        id="00N3w000009CuQ9"
                        v-model="form[`00N3w000009CuQ9`]"
                        v-bind="$attrs"
                        label="Street 2"
                        class="py-3"
                        :rules="[maxLength(200)]"
                        hide-details
                        dark
                    />
                    <v-row>
                        <v-col>
                            <base-text-field
                                id="00N3w000009CuQE"
                                v-model="form[`00N3w000009CuQE`]"
                                v-bind="$attrs"
                                label="City"
                                :rules="[
                                    (d) =>
                                        d.length <= 50 ||
                                        'Input length too long.'
                                ]"
                                counter="50"
                            />
                        </v-col>
                        <v-col>
                            <base-text-field
                                id="00N3w000009CuPu"
                                v-model="form[`00N3w000009CuPu`]"
                                v-bind="$attrs"
                                label="Country"
                                :rules="[
                                    (d) =>
                                        d.length <= 100 ||
                                        'Input length too long.'
                                ]"
                                counter="100"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <base-text-field
                                id="00N3w000009CuQO"
                                v-model="form[`00N3w000009CuQO`]"
                                v-bind="$attrs"
                                label="State"
                                :rules="[
                                    (d) =>
                                        d.length <= 100 ||
                                        'Input length too long.'
                                ]"
                                counter="100"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <base-text-field
                                id="00N3w000009CuQT"
                                v-model="form[`00N3w000009CuQT`]"
                                v-bind="$attrs"
                                label="Postal Code"
                                :rules="[
                                    (d) =>
                                        d.length <= 20 ||
                                        'Input length too long.'
                                ]"
                                counter="20"
                            />
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="00N3w000009CuPp"
                            v-model="form[`00N3w000009CuPp`]"
                            v-bind="$attrs"
                            label="Company Name"
                            :rules="[required, maxLength(255)]"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-text-field
                            id="00N3w000009CuPu"
                            v-model="form[`00N3w000009CuPu`]"
                            v-bind="$attrs"
                            label="Country"
                            :rules="[required, maxLength(100)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>

                <v-row label="Reference Numbers">
                    <v-col v-if="!hideRmaNumber" cols="12" sm="6">
                        <base-text-field
                            id="00N3w000009Cv4T"
                            v-model="form[`00N3w000009Cv4T`]"
                            v-bind="$attrs"
                            label="RMA Number"
                            :rules="[maxLength(30)]"
                            :disabled="!!rmaNumber"
                            hide-details
                            dark
                        />
                    </v-col>
                    <v-col v-if="!hideSerialNumber" cols="12" sm="6">
                        <base-text-field
                            id="00Nd0000007xcSa"
                            v-model="form[`00Nd0000007xcSa`]"
                            :disabled="!!serialNumber"
                            v-bind="$attrs"
                            label="Getac Serial Number"
                            :rules="[maxLength(50)]"
                            hide-details
                            dark
                        />
                    </v-col>
                </v-row>

                <v-row :class="privateForm && 'hidden'">
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            v-model="leadOptInBool"
                            v-bind="$attrs"
                            label="Lead Opt In"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            v-model="dataProcessConsentBool"
                            v-bind="$attrs"
                            label="Data Process Consent"
                        />
                    </v-col>
                </v-row>
            </div>
            <div
                class="pt-3"
                :class="
                    alignButton === 'right'
                        ? 'd-flex align-end flex-column'
                        : ''
                "
                @click="validateOnSubmission"
            >
                <base-btn
                    v-if="submitted === 0"
                    :block="$vuetify.breakpoint.smAndDown"
                    outlined
                    align="right"
                    :disabled="!isValid"
                    @click="submit"
                >
                    Submit
                </base-btn>
            </div>
        </v-form>
        <v-progress-circular
            v-if="submitted === 1"
            indeterminate
            color="primary"
        />
        <div v-if="submitted >= 2"> Case Successfully submitted </div>

        <div v-if="debug">
            <br />
            -------------------------- DEBUG --------------------------
            <br />
            <v-btn @click="progressStage">Progress stage {{ submitted }}</v-btn>
            <v-btn @click="viewIFrame = !viewIFrame">
                Toggle IFrame visibility
            </v-btn>
            <br />
            iFrame URL:
            <u>
                <i>
                    {{ iFrameURL || 'No URL in iFrame' }}
                </i>
            </u>
            <br />
        </div>
        <iframe
            id="sf_to_target"
            src=""
            :class="!viewIFrame ? 'hidden' : ''"
            name="sf_to_target"
            @load="handleIFrameChange"
        />
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    name: `SalesForceForm`,
    directives: { mask },
    props: {
        // eslint-disable-next-line vue/prop-name-casing
        alignButton: {
            type: String,
            default: ``
        },
        rmaNumber: {
            type: String,
            default: () => ``
        },
        serialNumber: {
            type: String,
            default: () => ``
        },
        modelName: {
            type: String,
            default: () => ``
        },
        showFullAddress: {
            type: Boolean
        },
        privateForm: {
            type: Boolean
        },
        debug: {
            type: Boolean
        },
        debugSF: {
            type: Boolean
        },
        subject: {
            type: String,
            required: false,
            default: ''
        },
        caseType: {
            type: String,
            required: false,
            default: ''
        },
        hideCaseType: {
            type: Boolean,
            required: false,
            default: false
        },
        hideSubject: {
            type: Boolean,
            required: false,
            default: false
        },
        hideSerialNumber: {
            type: Boolean,
            required: false,
            default: false
        },
        hideRmaNumber: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        required: (d) => !!d || `Required`,
        maxLength: (len) => (d) => d.length <= len || `Input length too long`,
        // action: `https://postman-echo.com/post`,
        action: `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8`,
        caseTypeList: [`Product`, `Quote`, `Question`, `Problem`],
        submitted: 0,
        iFrameURL: undefined,
        cleanDesc: undefined,
        viewIFrame: false,
        isValid: false,
        form: {
            '00N3w000009CuQd': `0`,
            '00N3w000009CuQY': `0`,
            '00N3w000009CuPf': ``,
            '00N3w000009CuPk': ``,
            '00N3w000009Cv4O': ``,
            '00N3w000009CuPz': ``,
            subject: ``,
            description: ``,
            email: ``,
            '00Nd00000076HZs': ``,
            '00N3w000009Cv4T': ``,
            '00Nd0000007xcSa': ``,
            '00N3w000009CuPp': ``,
            '00N3w000009CuQ4': ``,
            '00N3w000009CuQ9': ``,
            '00N3w000009CuQE': ``,
            '00N3w000009CuPu': ``,
            '00N3w000009CuQO': ``,
            '00N3w000009CuQT': ``,
            orgid: `00Dd0000000iOOU`,
            status: `New`,
            priority: 3,
            debug: `0`,
            debugEmail: ``
        }
    }),
    computed: {
        retURL() {
            return `${
                document && document.location && document.location.origin
            }/help-support/complete`
        },
        email: {
            get() {
                return this.form[`00N3w000009Cv4O`]
            },
            set(v) {
                this.form[`00N3w000009Cv4O`] = v
                this.form.email = v
            }
        },

        description: {
            set(v) {
                let value = v

                if (this.modelName) {
                    value = `Unit Model: ${this.modelName}\n\n${v}`
                    this.cleanDesc = v
                }
                this.form.description = value
            },
            get() {
                if (typeof this.cleanDesc === `string`) return this.cleanDesc
                return this.form.description
            }
        },

        leadOptInBool: {
            get() {
                return this.form[`00N3w000009CuQY`] === `1`
            },
            set(v) {
                this.form[`00N3w000009CuQY`] = v ? `1` : `0`
            }
        },
        dataProcessConsentBool: {
            get() {
                return this.form[`00N3w000009CuQd`] === `1`
            },
            set(v) {
                this.form[`00N3w000009CuQd`] = v ? `1` : `0`
            }
        },
        debugBool: {
            get() {
                return this.form.debug === `1`
            },
            set(v) {
                this.form.debug = v ? `1` : `0`
            }
        }
    },
    watch: {
        caseType: {
            immediate: true,
            handler() {
                if (
                    this.caseType &&
                    this.caseType.length &&
                    this.caseTypeList.includes(this.caseType)
                ) {
                    this.form[`00Nd00000076HZs`] = this.caseType
                }
            }
        },
        subject: {
            immediate: true,
            handler() {
                if (this.subject && this.subject.length) {
                    this.form.subject = this.subject
                }
            }
        },
        submitted(key) {
            let state
            switch (key) {
                case 0:
                    state = `started`
                    break
                case 1:
                    state = `processing`
                    break
                case 2:
                    state = `complete`
                    break
                default:
                    state = `unknown`
            }

            this.$emit(state)
            this.$emit(`change`, state)
        }
    },
    mounted() {
        this.debugBool = this.debugSF

        this.leadOptInBool = this.privateForm
        this.dataProcessConsentBool = this.privateForm

        const user = this.$auth.user
        this.email = user.email
        user.firstName && (this.form[`00N3w000009CuPf`] = user.firstName)
        user.lastName && (this.form[`00N3w000009CuPk`] = user.lastName)
        user.phone && (this.form[`00N3w000009CuPz`] = user.phone)
        user.company && (this.form[`00N3w000009CuPp`] = user.company)
        this.form[`00Nd0000007xcSa`] = this.serialNumber
        this.form[`00N3w000009Cv4T`] = this.rmaNumber

        if (user.address) {
            let a
            if (user.address.billing) a = `billing`
            else if (user.address.shipping) a = `shipping`

            if (a) {
                this.form[`00N3w000009CuQ4`] = user.address[a].line1
                this.form[`00N3w000009CuQ9`] = user.address[a].line2
                this.form[`00N3w000009CuQE`] = user.address[a].city
                this.form[`00N3w000009CuPu`] = user.address[a].country
                this.form[`00N3w000009CuQO`] = user.address[a].state
                this.form[`00N3w000009CuQT`] = user.address[a].postalCode
            }
        }
    },
    methods: {
        validateOnSubmission() {
            this.$refs[`sf-case-form`].validate()
        },
        submit() {
            if (this.isValid) this.$refs[`sf-case-form`].$el.submit()
        },
        getSFIFrame() {
            return document && document.getElementById(`sf_to_target`)
        },
        progressStage() {
            this.submitted++
            if (this.submitted > 2) this.submitted = 0
            console.debug(this.submitted)
        },
        handleIFrameChange(d) {
            const frame = this.getSFIFrame()
            if (frame) {
                this.iFrameURL = frame.src
                if (frame.contentWindow.location.href === this.retURL)
                    this.submitted = 2
            }
        }
    }
}
</script>
